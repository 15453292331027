import React, { forwardRef, useEffect, useState, useMemo } from 'react';

import { connect } from "react-redux";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import useText from 'hooks/useText';

import Flag from 'components/common/flag';

import { Dropdown } from 'antd';

import { PROMO_SECTION_TYPE } from 'constants/common.constants';

import { inIframe } from 'utils/common';

import configurationType from "types/configuration.type";

const Menu = forwardRef(({
    configuration
}, ref) => {

    const { t } = useText();

    const navigate = useNavigate();
    const searchParams = useParams();

    const [queryStringParams] = useSearchParams();

    const currentLn = useMemo(() => (searchParams.lang || (configuration.defaultLanguageCode ?? "")).toUpperCase(), [])

    const [showNavbar, setShowNavbar] = useState(false);

    const scrollToTarget = target => {
        const scrollDemo = document.querySelector(".rt--promo");
        if(!scrollDemo) return;

        const href = target.getAttribute('data-href');
        const targetElement = document.getElementById(href.replace('#', ''));
        if(!targetElement) return;
        const rect = targetElement.offsetTop
        const gap = 60;
        const element = rect - gap;
        scrollDemo.scrollTop = element
    }

    const handleShowNavbar = e => {
        setShowNavbar(!showNavbar);
        window.location.hash = "";
        setTimeout(() => {
            scrollToTarget(e.target);
        }, 0)
    }

    const becomeAffiliate = () => {
        if (configuration?.portalDomain && !inIframe()) {
            window.location.href = "https://" + configuration?.portalDomain + "/register?id=" + configuration.id
        }
    }

    const login = () => {
        if (configuration?.portalDomain && !inIframe()) {
            window.location.href = "https://" + configuration?.portalDomain + "/login"
        }
    }

    const changeLanguage = lang => {
        if(!lang) return;
        if (lang?.toLowerCase() === currentLn?.toLowerCase()) return;

        navigate({
            pathname: `/${lang.toLowerCase()}`,
            search: queryStringParams.toString(),
        })

        window.location.reload();
    }

    const menuItems = [
        {
            key: "commission",
            enabled: configuration?.sections?.commission?.enabled ?? false,
            sectionType: PROMO_SECTION_TYPE.COMMISSION,
        },
        {
            key: "marketingTools",
            enabled: configuration?.sections?.marketingTools?.enabled ?? false,
            sectionType: PROMO_SECTION_TYPE.MARKETING_TOOLS,
        },
        {
            key: "testimonials",
            enabled: configuration?.sections?.testimonial?.enabled ?? false,
            sectionType: PROMO_SECTION_TYPE.TESTIMONIAL,
        },
        {
            key: "faq",
            enabled: configuration?.sections?.faq?.enabled ?? false,
            sectionType: PROMO_SECTION_TYPE.FAQ,
        },
        {
            key: "contactUs",
            enabled: true,
            sectionType: PROMO_SECTION_TYPE.FOOTER,
        }
    ]

    const languages = configuration?.languages ?? {};

    useEffect(() => {
        if (!languages[currentLn.toLowerCase()]) {
            changeLanguage(configuration.defaultLanguageCode)
        }
    }, [languages])

    return (
        <div className="rt--promo-headerMenu" ref={ref}>
            <div className='rt--promo-headerMenu-block rt--promo-block-gap'>
                <div className='rt--promo-headerMenu-block-content rt--background-color rt--flex rt--align-center rt--justify-between rt--pl-24 rt--pr-24'>
                    <div className="menu-icon rt--mr-12" onClick={handleShowNavbar}>
                        {
                            showNavbar === true ?
                                <i className='icon-close rt--font-24'></i>
                                :
                                <i className='icon-hamburger rt--font-24'></i>
                        }
                    </div>
                    <a className='rt--flex rt--flex-equal'>
                        <div className='rt--promo-headerMenu-block-content-logo'></div>
                    </a>
                    <div className='rt--flex'>
                        <div className={`nav-elements rt--flex  ${showNavbar && "nav-elements-active"}`}>
                            <ul className='rt--promo-headerMenu-block-content-list rt--flex rt--align-center'>
                                {
                                    menuItems
                                        .filter(item => item.enabled)
                                        .map(item => (
                                            <li key={item.key}>
                                                <a
                                                    onClick={handleShowNavbar}
                                                    className='rt--header-menu-color rt--font-14 rt--font-bold'
                                                    data-href={`${item.sectionType}`}

                                                >
                                                    {
                                                        t(PROMO_SECTION_TYPE.COMMON, item.key)
                                                    }
                                                </a>
                                            </li>
                                        ))
                                }

                                <li>
                                    <Dropdown
                                        placement='bottomRight'
                                        className='rt--promo-headerMenu-language'
                                        overlay={(
                                            <div className='rt--promo-headerMenu-language-content rt--flex rt--flex-col'>
                                                {
                                                    Object.keys(languages)
                                                        .filter(lang => languages[lang])
                                                        .map(lang => (
                                                            <div
                                                                className={
                                                                    'rt--promo-headerMenu-language-content-lang rt--flex rt--align-center' +
                                                                    (lang.toLowerCase() === currentLn.toLowerCase() ? " rt--promo-headerMenu-language-content-lang-active" : "")
                                                                }
                                                                key={lang}
                                                                onClick={() => changeLanguage(lang)}
                                                            >
                                                                <Flag code={lang} />
                                                                <span
                                                                    className='rt--header-menu-color rt--font-14 rt--font-regular rt--ml-6'
                                                                >
                                                                    {
                                                                        t(PROMO_SECTION_TYPE.COMMON, lang)
                                                                    }
                                                                </span>
                                                            </div>
                                                        ))
                                                }
                                            </div>
                                        )}

                                    >
                                        <div className="rt--flex rt--align-center rt--promo-headerMenu-language-selected">
                                            <Flag code={currentLn.toLowerCase()} />
                                            <span className='rt--header-menu-color rt--font-bold rt--font-normal rt--ml-6 rt--font-14'>
                                                {
                                                    t(PROMO_SECTION_TYPE.COMMON, currentLn.toLowerCase())
                                                }
                                            </span>
                                            <i className="icon-down rt--font-24" />
                                        </div>
                                    </Dropdown>
                                </li>
                                {
                                    configuration?.allowRegistration && (
                                        <li>
                                            <button
                                                className='nav-elements-affilate-btn rt--promo-button rt--promo-button-primary'
                                                onClick={becomeAffiliate}
                                            >
                                                {
                                                    t(PROMO_SECTION_TYPE.COMMON, "becomeAffiliate")
                                                }
                                            </button>
                                        </li>
                                    )
                                }

                            </ul>
                        </div>
                    </div>
                    <button
                        className='rt--promo-button rt--promo-button-secondary'
                        onClick={login}
                    >
                        {
                            t(PROMO_SECTION_TYPE.COMMON, "login")
                        }
                    </button>
                    {
                        configuration?.allowRegistration && (
                            <button
                                className='rt--promo-headerMenu-block-content-list-affilate-btn rt--promo-button rt--promo-button-primary rt--ml-16'
                                onClick={becomeAffiliate}
                            >
                                {
                                    t(PROMO_SECTION_TYPE.COMMON, "becomeAffiliate")
                                }
                            </button>
                        )
                    }

                </div>
            </div>
        </div>
    )
})

/** Menu propTypes
    * PropTypes
*/
Menu.propTypes = {
    /** Redux state property, configuration */
    configuration: configurationType
}

const mapStateToProps = (state) => {
    return {
        configuration: state.common.configuration
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Menu);